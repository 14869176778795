import React, { createContext, useState } from "react";
const CaContext = createContext();

const CaProvider = ({ children }) => {
  const [inEditCa, setInEditCa] = useState(null);

  // Provide the context values to the components
  const contextValues = { inEditCa, setInEditCa };

  return (
    <CaContext.Provider value={contextValues}>{children}</CaContext.Provider>
  );
};

// Export the context and the provider
export { CaContext, CaProvider };
