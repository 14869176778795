import client from "./client";

export const caRegistration = async (bdm_id, obj) => {
  try {
    const data = await client.post(`/ca/caRegister/${bdm_id}`, obj);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const caAllData = async () => {
  try {
    const data = await client.get(`/ca`);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const caBDMData = async (id) => {
  try {
    const data = await client.get(`/ca/${id}`);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const caTLData = async (id) => {
  try {
    const data = await client.get(`/ca/teamData/${id}`);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const caUpdateData = async (ca_id, obj) => {
  try {
    const data = await client.post(`/ca/caUpdate/${ca_id}`, obj);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const caMeetingInsert = async (obj) => {
  try {
    const data = await client.post(`/ca/caMeetingInsert`, obj);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const caMeetingUpdate = async (meetingID, obj) => {
  try {
    const data = await client.post(`/ca/caMeetingUpdate/${meetingID}`, obj);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
