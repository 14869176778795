import React, { useEffect, useState } from "react";
import { MdHome } from "react-icons/md";
import { FaWpforms } from "react-icons/fa6";
import { SiGoogleforms } from "react-icons/si";
import MainDashboard from "views/admin/default";
import NAIFForm from "views/admin/naifForm/NAIFForm";
import NAIFTable from "views/admin/naifForm/NAIFTable";
import CaForm from "views/admin/cacommission/caForm";
import CaTable from "views/admin/cacommission/caTable";
import { getNaif, getCaCommision } from "helper/EncrptionUtils";
import CaDashboard from "views/admin/default/CaDashboard";
import { MdDashboardCustomize } from "react-icons/md";

// Define the routes array here, outside of the component
export const AllRoutes = () => {
  const [naifRole, setNaifRole] = useState(null);
  const [caCommission, setCaCommission] = useState(null);

  useEffect(() => {
    setNaifRole(getNaif());
    setCaCommission(getCaCommision());
  }, []);

  return [
    {
      name: "Dashboard",
      layout: "/admin",
      path: "default",
      icon: <MdHome className="h-6 w-6" />,
      component: <MainDashboard />,
      showInSidebar: naifRole ? true : false,
    },
    {
      name: "CA Dashboard",
      layout: "/admin",
      path: "cadashboard",
      icon: <MdDashboardCustomize className="h-6 w-6" />,
      component: <CaDashboard />,
      showInSidebar: caCommission ? true : false,
    },
    {
      name: "Registration Form",
      layout: "/admin",
      path: "naifForm",
      icon: <FaWpforms className="h-6 w-6" />,
      component: <NAIFForm />,
      showInNaif: true,
      showInSidebar: naifRole ? true : false,
    },
    {
      name: "ALL Registration Forms",
      layout: "/admin",
      path: "naifdata",
      icon: <SiGoogleforms className="h-6 w-6" />,
      component: <NAIFTable />,
      showInSidebar: naifRole ? true : false,
    },
    {
      name: "CA Form",
      layout: "/admin",
      path: "caform",
      icon: <FaWpforms className="h-6 w-6" />,
      component: <CaForm />,
      showInSidebar: caCommission ? true : false,
    },
    {
      name: "ALL CA Forms",
      layout: "/admin",
      path: "catable",
      icon: <SiGoogleforms className="h-6 w-6" />,
      component: <CaTable />,
      showInSidebar: caCommission ? true : false,
    },
  ];
};
