import React, { useState, useEffect } from "react";
import { FaProjectDiagram } from "react-icons/fa";
import { SiGotomeeting } from "react-icons/si";
import { MdOutlineDoneOutline } from "react-icons/md";
import { caInsight } from "./variables/columnsData";
import Widget from "components/widget/Widget";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getId } from "helper/EncrptionUtils";
import { getCaReport } from "api/report";
import CaInsightTable from "./components/CaInsightTable";
import { getCaBDMReport } from "api/report";
import { getCaCommision } from "helper/EncrptionUtils";

const CaDashboard = () => {
  const [query, setQuery] = useState([]);
  const [queryBDM, setQueryBDM] = useState([]);
  const today = moment().toDate();
  const role = getCaCommision();
  const [dateRange, setDateRange] = useState([today, today]);
  const [startDate, endDate] = dateRange?.map((date) => {
    return date ? moment(date).format("YYYY-MM-DD") : null; // Handle possible null values
  });

  const fetchCaReport = async () => {
    const data = await getCaReport(startDate, endDate);
    console.log(data);
    if (data.success) {
      setQuery(data.result);
    }
    try {
    } catch (error) {
      console.log("errro data report", error);
    }
  };
  const fetchCaBDMReport = async () => {
    const data = await getCaBDMReport(getId, startDate, endDate);
    if (data.success) {
      setQueryBDM(data.result[0]);
    }
    try {
    } catch (error) {
      console.log("errro data report", error);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      // Ensure valid dates before fetching
      fetchCaReport();
      fetchCaBDMReport();
    }
  }, [startDate, endDate]);

  return (
    <div>
      <div className="my-2 flex justify-end">
        <DatePicker
          selectsRange
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          onChange={(update) => {
            setDateRange(update);
          }}
          isClearable={true}
          placeholderText="Select date range"
          className="w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none"
        />
      </div>
      {role === "User" && (
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-5">
          <Widget
            icon={<FaProjectDiagram className="h-7 w-7" />}
            title={"INTRODUCTION"}
            subtitle={queryBDM?.introduction_count || "0"}
          />
          <Widget
            icon={<SiGotomeeting className="h-7 w-7" />}
            title={"MEETING"}
            subtitle={queryBDM?.meeting_count || "0"}
          />
          <Widget
            icon={<MdOutlineDoneOutline className="h-7 w-7" />}
            title={"TIE-UP DONE"}
            subtitle={queryBDM?.tie_up_done_count || "0"}
          />
        </div>
      )}

      {(role === "Admin" || role === "TL") && (
        <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
          <div>
            <CaInsightTable columnsData={caInsight} tableData={query} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CaDashboard;
