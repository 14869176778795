import React, { useState, useEffect } from "react";
import { FaProjectDiagram } from "react-icons/fa";
import { IoBriefcaseSharp } from "react-icons/io5";
import { MdOutlinePayment } from "react-icons/md";

import {
  applicationStatusColumn,
  columnsDataCheck,
} from "./variables/columnsData";
import Widget from "components/widget/Widget";
import CheckTable from "./components/CheckTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getNaifReport } from "api/report";
import { getNaifBDMReport } from "api/report";
import { getId } from "helper/EncrptionUtils";
import { getNaif } from "helper/EncrptionUtils";
import ApplicationStatus from "./components/ApplicationStatus";
import { getApplicationStatus } from "api/report";
import { getApplicationStatusForBDM } from "api/report";
import { FaWpforms } from "react-icons/fa6";
import { IoMdDocument } from "react-icons/io";
import { SiProcesswire } from "react-icons/si";
import { GrCompliance } from "react-icons/gr";
import { IoMdCloseCircle } from "react-icons/io";

const Dashboard = () => {
  const [query, setQuery] = useState([]);
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [applicationStatusBdm, setApplicationStatusBdm] = useState([]);
  const [queryBDM, setQueryBDM] = useState([]);
  const today = moment().toDate();
  const role = getNaif();
  const [dateRange, setDateRange] = useState([today, today]);
  const [startDate, endDate] = dateRange?.map((date) => {
    return date ? moment(date).format("YYYY-MM-DD") : null; // Handle possible null values
  });

  const fetchNaifReport = async () => {
    const data = await getNaifReport(startDate, endDate);
    if (data.success) {
      setQuery(data.result);
    }
    try {
    } catch (error) {
      console.log("errro data report", error);
    }
  };
  const fetchNaifBDMReport = async () => {
    const data = await getNaifBDMReport(getId(), startDate, endDate);
    if (data.success) {
      setQueryBDM(data.result[0]);
    }
    try {
    } catch (error) {
      console.log("errro data report", error);
    }
  };
  const fetchgetApplicationStatusForBDM = async () => {
    const data = await getApplicationStatusForBDM(getId(), startDate, endDate);

    if (data.success) {
      setApplicationStatusBdm(data.result[0]);
      console.log(data.result[0]);
    }
    try {
    } catch (error) {
      console.log("errro data report", error);
    }
  };

  const fetchNaifStatusReport = async () => {
    const data = await getApplicationStatus(startDate, endDate);
    if (data.success) {
      setApplicationStatus(data.result);
    }
    try {
    } catch (error) {
      console.log("errro data report", error);
    }
  };

  const formatNumberToIndianLocale = (number) => {
    if (number === null || number === undefined) return "-";
    return new Intl.NumberFormat("en-IN").format(number);
  };

  useEffect(() => {
    if (startDate && endDate) {
      // Ensure valid dates before fetching
      fetchNaifReport();
      fetchNaifBDMReport();
      fetchNaifStatusReport();
      fetchgetApplicationStatusForBDM();
    }
  }, [startDate, endDate]);

  return (
    <div>
      <div className="my-2 flex justify-end">
        <DatePicker
          selectsRange
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          onChange={(update) => {
            setDateRange(update);
          }}
          isClearable={true}
          placeholderText="Select date range"
          className=" z-50 w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none"
        />
      </div>
      {role === "User" && (
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-5">
          <Widget
            icon={<FaProjectDiagram className="h-7 w-7" />}
            title={"Project Size"}
            subtitle={
              formatNumberToIndianLocale(queryBDM?.total_project_size) || "0"
            }
          />
          <Widget
            icon={<MdOutlinePayment className="h-7 w-7" />}
            title={"Advance Payment"}
            subtitle={
              formatNumberToIndianLocale(queryBDM?.booking_value) || "0"
            }
          />
          <Widget
            icon={<IoBriefcaseSharp className="h-7 w-7" />}
            title={"Cases"}
            subtitle={
              formatNumberToIndianLocale(queryBDM?.application_count) || "0"
            }
          />
          <Widget
            icon={<FaWpforms className="h-7 w-7" />}
            title={"Form Submission"}
            subtitle={applicationStatusBdm?.[`Form Submission`] || "0"}
          />
          <Widget
            icon={<IoMdDocument className="h-7 w-7" />}
            title={"Documents Pending"}
            subtitle={applicationStatusBdm?.[`Documents Pending`] || "0"}
          />
          <Widget
            icon={<SiProcesswire className="h-7 w-7" />}
            title={"Validation Inprocess"}
            subtitle={applicationStatusBdm?.[`Validation Inprocess`] || "0"}
          />
          <Widget
            icon={<GrCompliance className="h-7 w-7" />}
            title={"Validation Completed"}
            subtitle={applicationStatusBdm?.[`Validation Completed`] || "0"}
          />
          <Widget
            icon={<IoMdCloseCircle className="h-7 w-7" />}
            title={"Lost"}
            subtitle={applicationStatusBdm?.[`Lost`] || "0"}
          />
        </div>
      )}
      {role === "Admin" && (
        <div className=" mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
          <div>
            <CheckTable columnsData={columnsDataCheck} tableData={query} />
          </div>
          <div>
            <ApplicationStatus
              columnsData={applicationStatusColumn}
              tableData={applicationStatus}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
