import React from "react";
import { Table, Button } from "antd";

const DocumentsTab = ({ documents }) => {
  const documentColumns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => window.open(record.fileUrl, "_blank")}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Table
      columns={documentColumns}
      dataSource={documents || []}
      pagination={false}
      rowKey="fileName"
    />
  );
};

export default DocumentsTab;
