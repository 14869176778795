import React, { useContext } from "react";
import { Button, DatePicker, TimePicker, Select, Form, message } from "antd";
import moment from "moment";
import { CaContext } from "context/CaContext";
import { caMeetingInsert } from "api/ca";
import { caMeetingUpdate } from "api/ca";

const { Option } = Select;

const MeetingInfoComponent = ({
  meetingInfo,
  onMeetingInfoChange,
  editable,
  toggleEditable,
}) => {
  const caContext = useContext(CaContext);
  const { inEditCa, setInEditCa } = caContext;

  const handleDateChange = (date) => {
    onMeetingInfoChange({
      ...meetingInfo,
      meetingDate: date ? date.format("YYYY-MM-DD") : null,
    });
  };

  const handleTimeChange = (time) => {
    onMeetingInfoChange({
      ...meetingInfo,
      meetingTime: time ? time.format("HH:mm") : null,
    });
  };

  const handleStatusChange = (status) => {
    onMeetingInfoChange({ ...meetingInfo, meetingStatus: status });
  };

  const handleSave = async () => {
    if (!inEditCa.meeting_id) {
      try {
        const meetingInsert = await caMeetingInsert({
          ca_id: inEditCa.id,
          meetingInfo,
        });
        console.log(meetingInfo);
        if (meetingInsert.success) {
          message.success(meetingInsert.message);
          setInEditCa({
            ...inEditCa, // Spread previous state
            meeting_id: meetingInsert.meetingID, // Add the new meetingID
          });
        }
      } catch (error) {
        console.log("Meeting Insert Errro", error);
      }
    } else if (inEditCa.meeting_id) {
      try {
        const meetingUpdate = await caMeetingUpdate(inEditCa.meeting_id, {
          meetingInfo,
        });
        if (meetingUpdate.success) {
          message.success(meetingUpdate.message);
        }
      } catch (error) {
        console.log("Meeting Update Errro", error);
      }
    }
    toggleEditable(); // Toggle off the editable mode
  };

  return (
    <div>
      <div className="flex justify-end space-x-3 px-4">
        <Button onClick={toggleEditable}>
          {editable ? "Cancel Edit" : "Edit"}
        </Button>
        {editable && (
          <Button type="primary" className="ml-2" onClick={handleSave}>
            Save
          </Button>
        )}
      </div>
      <Form layout="vertical">
        <Form.Item label="Meeting Date">
          <DatePicker
            disabled={!editable}
            value={
              meetingInfo.meetingDate ? moment(meetingInfo.meetingDate) : null
            }
            onChange={handleDateChange}
          />
        </Form.Item>
        <Form.Item label="Meeting Time">
          <TimePicker
            disabled={!editable}
            value={
              meetingInfo.meetingTime
                ? moment(meetingInfo.meetingTime, "HH:mm")
                : null
            }
            onChange={handleTimeChange}
          />
        </Form.Item>
        <Form.Item label="Meeting Status" className="w-1/2">
          <Select
            disabled={!editable}
            value={meetingInfo.meetingStatus}
            onChange={handleStatusChange}
          >
            <Option value="Scheduled">Scheduled</Option>
            <Option value="ReScheduled">ReScheduled</Option>
            <Option value="Done">Done</Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MeetingInfoComponent;
