import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import EditDetails from "./EditDetails"; // First tab: Details
import DocumentsTab from "./DocumentsTab"; // Second tab: Documents
import NaifStatus from "./NaifStatus";
import { getNaif } from "helper/EncrptionUtils";

const { TabPane } = Tabs;

const TabModal = ({ record }) => {
  const [data, setData] = useState(record);

  useEffect(() => {
    setData(record); // Update internal state when record changes
    console.log("TabModal updated with record:", record);
  }, [record]);

  if (!data) return <p>No record data available</p>;
  return (
    <Tabs defaultActiveKey="1" className="font-semibold">
      {/* First Tab: Edit Details */}
      <TabPane tab="Details" key="1">
        <EditDetails record={data} />
      </TabPane>
      {/* Second Tab: Documents */}
      <TabPane tab="Documents" key="2">
        <DocumentsTab documents={data?.documents} />
      </TabPane>
      {/* Second Tab: Documents */}
      {getNaif() === "Admin" && (
        <TabPane tab="Status" key="3">
          <NaifStatus data={data} />
        </TabPane>
      )}
    </Tabs>
  );
};

export default TabModal;
