/* eslint-disable */
import { HiX } from "react-icons/hi";
import Links from "./components/Links"; // Ensure this is the updated SidebarLinks
import avatar from "assets/img/profile/egniol.png";
import { AllRoutes } from "./components/AllRoutes";

const Sidebar = ({ open, onClose }) => {
  const allRoutes = AllRoutes();

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[30px] flex items-center`}>
        <div className=" h-12 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          <img className="h-full w-full" src={avatar} alt="" />
        </div>
      </div>
      <div className="mb-7 mt-[48px] h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav items */}
      <ul className="mb-auto pt-1">
        <Links routes={allRoutes} /> {/* Pass the filtered routes here */}
      </ul>
      {/* Nav items end */}
    </div>
  );
};

export default Sidebar;
