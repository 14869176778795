import React from "react";
import Card from "../../../../components/card";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

function ApplicationStatus({ tableData }) {
  const [sorting, setSorting] = React.useState([]);
  const columnHelper = createColumnHelper();

  const formatNumberToIndianLocale = (number) => {
    if (number === null || number === undefined) return "-";
    return new Intl.NumberFormat("en-IN").format(number);
  };

  const columns = [
    { id: "name", header: "NAME", accessor: "name" },

    {
      id: "Form Submission",
      header: "FORM SUB.",
      accessor: "Form Submission",
      formatter: formatNumberToIndianLocale,
    },
    {
      id: "Documents Pending",
      header: "DOC. PENIDNG",
      accessor: "Documents Pending",
      formatter: formatNumberToIndianLocale,
    },
    {
      id: "Validation Inprocess",
      header: "VALIDATION INPROCESS",
      accessor: "Validation Inprocess",
    },
    {
      id: "Validation Completed",
      header: "VALIDATION COMPLETED",
      accessor: "Validation Completed",
    },
    {
      id: "Lost",
      header: "LOST",
      accessor: "Lost",
    },
    {
      id: "grandtotal",
      header: "GRAND TOTAL",
      accessor: "grandtotal",
    },
  ].map((col) =>
    columnHelper.accessor(col.accessor, {
      id: col.id,
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          {col.header}
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {col.formatter
            ? col.formatter(info.getValue()) // Apply formatting if specified
            : info.getValue()}
        </p>
      ),
    })
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Card extra="w-full sm:overflow-auto px-6">
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          NAIF Admin Report
        </div>
      </header>
      <div className="custom-scrollbar  mt-8 overflow-x-auto">
        {/* Outer div to handle Y scrolling */}
        <div className="custom-scrollbar   max-h-80 overflow-y-auto">
          <table className="w-full">
            {/* Sticky header */}
            <thead className="sticky top-0 bg-white dark:bg-gray-800">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-2 pt-4 text-start"
                    >
                      <div className="text-center text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() &&
                          (header.column.getIsSorted() === "asc" ? "↑" : "↓")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* Scrollable body */}
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="table-row">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="min-w-[110px] border-white/0 py-3 pr-2 text-center"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
}

export default ApplicationStatus;
