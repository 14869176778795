import React, { createContext, useState } from "react";
const NaifContext = createContext();

const NaifProvider = ({ children }) => {
  const [inEditNaif, setInEditNaif] = useState(null);

  // Provide the context values to the components
  const contextValues = { inEditNaif, setInEditNaif };

  return (
    <NaifContext.Provider value={contextValues}>
      {children}
    </NaifContext.Provider>
  );
};

// Export the context and the provider
export { NaifContext, NaifProvider };
