import React, { useState, useEffect, useContext } from "react";
import { Table, Button, Input, Checkbox, Tooltip } from "antd";
import { Select } from "antd";
import moment from "moment";
import EditCaModal from "./EditCaModal";
import { caAllData } from "api/ca";
import { CaContext } from "context/CaContext";
import { caBDMData } from "api/ca";
import { getCaCommision } from "helper/EncrptionUtils";
import { getId } from "helper/EncrptionUtils";
import { useNavigate } from "react-router-dom";
import { caTLData } from "api/ca";
const { Option } = Select;

const CaTable = () => {
  const caContext = useContext(CaContext);
  const { inEditCa, setInEditCa } = caContext;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValues, setSearchValues] = useState({
    bdm_name: [],
    caFirmName: "",
    caName: "",
    caContactNumber: "",
    status: [],
  });
  let navigate = useNavigate();
  const [bdmNames, setBdmNames] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingData, setEditingData] = useState(null); // Data of the row being edited

  const fetchData = async () => {
    try {
      const data =
        getCaCommision() === "Admin"
          ? await caAllData()
          : getCaCommision() === "TL"
          ? await caTLData(getId())
          : await caBDMData(getId());

      if (data.success) {
        setData(data.result);
        setFilteredData(data.result);
        const uniqueBdmNames = [
          ...new Set(data.result.map((item) => item.bdm_name)),
        ];
        setBdmNames(uniqueBdmNames);

        const uniqueStatus = [
          ...new Set(data.result.map((item) => item.status)),
        ];
        setStatusOptions(uniqueStatus);
      }
    } catch (error) {
      console.log("error get ca Data", error);
    }
  };

  // Fetch the data and prepare the unique filter options
  useEffect(() => {
    fetchData();

    const uniqueBdmNames = [...new Set(data.map((item) => item.bdm_name))];
    setBdmNames(uniqueBdmNames);

    const uniqueStatus = [...new Set(data.map((item) => item.status))];
    setStatusOptions(uniqueStatus);
  }, []);

  // Handle text search filters
  const handleTextSearch = (value, column) => {
    const updatedSearchValues = { ...searchValues, [column]: value };
    setSearchValues(updatedSearchValues);
    filterData(updatedSearchValues);
  };

  // Handle checkbox filters
  const handleCheckboxChange = (value, column) => {
    const updatedSearchValues = { ...searchValues, [column]: value };
    setSearchValues(updatedSearchValues);
    filterData(updatedSearchValues);
  };

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  // Filter the data based on search criteria
  const filterData = (searchValues) => {
    const filtered = data.filter((item) => {
      const matchesBdmName = searchValues.bdm_name.length
        ? searchValues.bdm_name.includes(item.bdm_name)
        : true;
      const matchesStatus = searchValues.status.length
        ? searchValues.status.includes(item.status)
        : true;
      const matchesCaFirmName = searchValues.caFirmName
        ? item.caFirmName
            .toLowerCase()
            .includes(searchValues.caFirmName.toLowerCase())
        : true;
      const matchesCaName = searchValues.caName
        ? item.caName.toLowerCase().includes(searchValues.caName.toLowerCase())
        : true;
      const matchesCaContactNumber = searchValues.caContactNumber
        ? item.caContactNumber.includes(searchValues.caContactNumber)
        : true;

      return (
        matchesBdmName &&
        matchesStatus &&
        matchesCaFirmName &&
        matchesCaName &&
        matchesCaContactNumber
      );
    });

    setFilteredData(filtered);
  };

  const handleEdit = (record) => {
    setEditingData(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setEditingData(null);
  };

  const handleModalSubmit = (updatedData) => {
    // Update the data list
    const updatedList = data.map((item) =>
      item.id === editingData.id ? { ...item, ...updatedData } : item
    );
    setData(updatedList);
    setFilteredData(updatedList);
    handleModalClose();
  };
  useEffect(() => {
    if (!getCaCommision()) {
      navigate("/");
    }
  }, []);
  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{formatDate(text)}</span>,
    },
    {
      title: "CA Firm Name",
      dataIndex: "caFirmName",
      key: "caFirmName",
      filterDropdown: () => (
        <Input
          placeholder="Search by CA Firm Name"
          value={searchValues.caFirmName}
          onChange={(e) => handleTextSearch(e.target.value, "caFirmName")}
          className="p-2"
        />
      ),
    },
    {
      title: "CA Name",
      dataIndex: "caName",
      key: "caName",
      filterDropdown: () => (
        <Input
          placeholder="Search by CA Name"
          value={searchValues.caName}
          onChange={(e) => handleTextSearch(e.target.value, "caName")}
          className="p-2"
        />
      ),
    },
    {
      title: "CA Name",
      dataIndex: "caEmailId",
      key: "caEmailId",
      filterDropdown: () => (
        <Input
          placeholder="Search by CA Email ID"
          value={searchValues.caName}
          onChange={(e) => handleTextSearch(e.target.value, "caEmailId")}
          className="p-2"
        />
      ),
    },
    {
      title: "CA Contact Number",
      dataIndex: "caContactNumber",
      key: "caContactNumber",
      filterDropdown: () => (
        <Input
          placeholder="Search by CA Contact Number"
          value={searchValues.caContactNumber}
          onChange={(e) => handleTextSearch(e.target.value, "caContactNumber")}
          className="p-2"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterDropdown: () => (
        <Checkbox.Group
          options={statusOptions}
          value={searchValues.status}
          onChange={(value) => handleCheckboxChange(value, "status")}
          className="flex flex-col space-y-2 p-3"
        />
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (remarks) => (
        <Tooltip title={remarks}>
          <span>
            {remarks.length > 20 ? `${remarks.slice(0, 20)}...` : remarks}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "BDM Name",
      dataIndex: "bdm_name",
      key: "bdm_name",
      filterDropdown: () => (
        <Checkbox.Group
          options={bdmNames}
          value={searchValues.bdm_name}
          onChange={(value) => handleCheckboxChange(value, "bdm_name")}
          className="flex flex-col space-y-2 p-3"
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            handleEdit(record);
            setInEditCa(record);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div className="p-4">
      <Table
        dataSource={filteredData}
        columns={columns}
        pagination={true}
        rowKey="id"
        className="font-semibold"
      />
      <EditCaModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        width={1000}
        data={editingData}
        statusOptions={statusOptions}
        bdmNames={bdmNames}
      />
    </div>
  );
};

export default CaTable;
