import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import logo from "../assets/img/profile/egniol.png";
import { userLogin } from "api/users";
import { encrypt } from "helper/EncrptionUtils";
const LoginPage = ({}) => {
  // context utils
  let navigate = useNavigate();

  //   const { socketLogin } = useSocket();
  const handleLogin = async (email, name, hd) => {
    const org = [
      "egniol.in",
      "egniol.co.in",
      "egniol.org",
      "egniolservices.ca",
    ];
    if (!org.includes(hd)) {
      toast.error("Please Login with organizations emailId.");
    } else {
      const data = await userLogin({ email, name, hd });
      if (data.success) {
        toast.success(data.message);
        let id = encrypt(data.data.id + "");
        let name = encrypt(data.data.name);
        localStorage.setItem("id", id);
        localStorage.setItem("name", name);
        if (data.data.naif === "Admin" && data.data.caCommission === "Admin") {
          localStorage.setItem("naif", encrypt(data.data.naif));
          localStorage.setItem("caCommission", encrypt(data.data.caCommission));
          navigate("/admin/default");
        } else if (data.data.naif) {
          localStorage.setItem("naif", encrypt(data.data.naif));
          navigate("/admin/default");
        } else if (data.data.caCommission) {
          localStorage.setItem("caCommission", encrypt(data.data.caCommission));
          navigate("/admin/cadashboard");
        }
      } else {
        toast.error(data.message);
      }
    }
  };

  return (
    <div className="flex min-h-screen  items-center justify-center bg-gray-200 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 rounded-lg border-gray-800 bg-white p-6 shadow-lg shadow-gray-500">
        <div className="flex justify-center text-center text-2xl font-semibold text-gray-600">
          <img src={logo} width={140} height={70} />
        </div>
        <div className="flex justify-center">
          <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                const userData = jwtDecode(credentialResponse.credential);
                await handleLogin(
                  userData?.email,
                  userData?.name,
                  userData?.hd
                );
              }}
              onError={(error) => {
                console.log(error);
                toast.error("Login Failed");
              }}
            />
          </GoogleOAuthProvider>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
