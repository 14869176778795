import client from "./client";

export const getNaifReport = async (startDate, endDate) => {
  try {
    const data = await client.get(`/report/naif/${startDate}/${endDate}`);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getNaifBDMReport = async (bdm_id, startDate, endDate) => {
  try {
    const data = await client.get(
      `/report/naif/${bdm_id}/${startDate}/${endDate}`
    );
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getCaReport = async (startDate, endDate) => {
  try {
    const data = await client.get(`/report/ca/${startDate}/${endDate}`);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getCaBDMReport = async (bdm_id, startDate, endDate) => {
  try {
    const data = await client.get(
      `/report/ca/${bdm_id}/${startDate}/${endDate}`
    );
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getApplicationStatus = async (startDate, endDate) => {
  try {
    const data = await client.get(
      `/report/naifApplicationStatus/${startDate}/${endDate}`
    );
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getApplicationStatusForBDM = async (
  bdm_id,
  startDate,
  endDate
) => {
  try {
    const data = await client.get(
      `/report/naifApplicationStatusForBdm/${bdm_id}/${startDate}/${endDate}`
    );
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
