import React from "react";
import { Tabs } from "antd";
import MeetingInfoComponent from "./MeetingInfoComponent";
import MouUpload from "./MouUpload";

const { TabPane } = Tabs;

const TabsComponent = ({
  meetingInfo,
  onMeetingInfoChange,
  meetingInfoEditable,
  toggleEditable,
  caForm,
}) => {
  return (
    <Tabs defaultActiveKey="1" className="font-semibold">
      {/* CA Details Tab */}
      <TabPane tab="CA Details" key="1">
        {caForm}
      </TabPane>
      <TabPane tab="Meeting Information" key="2">
        <MeetingInfoComponent
          meetingInfo={meetingInfo}
          onMeetingInfoChange={onMeetingInfoChange}
          editable={meetingInfoEditable}
          toggleEditable={toggleEditable}
        />
      </TabPane>
      <TabPane tab="MOU Upload" key="3">
        <MouUpload />
      </TabPane>
    </Tabs>
  );
};

export default TabsComponent;
