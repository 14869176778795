export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROJECT SIZE",
    accessor: "total_project_size",
  },
  {
    Header: "AMOUNT",
    accessor: "booking_value",
  },
  {
    Header: "COUNT",
    accessor: "application_count",
  },
];

export const caInsight = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "INTRODUCTION",
    accessor: "introduction_count",
  },
  {
    Header: "MEETING",
    accessor: "meeting_count",
  },
  {
    Header: "TIE-UP DONE",
    accessor: "tie_up_done_count",
  },
];

export const applicationStatusColumn = [
  {
    Header: "NAME",
    accessor: "name",
  },
  { Header: "FORM SUB.", accessor: "Form Submission" },
  {
    Header: "DOC. PENIDNG",
    accessor: "Documents Pending",
  },
  {
    Header: "VALIDATION INPROCESS",
    accessor: "Validation Inprocess",
  },
  {
    Header: "VALIDATION COMPLETED",
    accessor: "Validation Completed",
  },
  {
    Header: "LOST",
    accessor: "Lost",
  },
  {
    Header: "GRAND TOTAL",
    accessor: "grandtotal",
  },
];
