import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, message } from "antd";
import TabsComponent from "./TabsComponent";
import { CaContext } from "context/CaContext";
import { caUpdateData } from "api/ca";

const { Option } = Select;

const EditCaModal = ({
  visible,
  onClose,
  onSubmit,
  data,
  statusOptions,
  bdmNames,
}) => {
  const caContext = useContext(CaContext);
  const { inEditCa, setInEditCa } = caContext;
  const [form] = Form.useForm();
  const [meetingInfo, setMeetingInfo] = useState({
    meetingDate: null,
    meetingTime: null,
    meetingStatus: "",
  });
  const [meetingInfoEditable, setMeetingInfoEditable] = useState(false);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      setMeetingInfo(data.meetingInfo || {});
    }
  }, [data, form]);

  const handleMeetingInfoChange = (updatedInfo) => {
    setMeetingInfo(updatedInfo);
  };

  const toggleEditable = () => {
    setMeetingInfoEditable(!meetingInfoEditable);
  };

  const handleOk = async () => {
    try {
      // Validate the form fields
      const values = await form.validateFields();
      onSubmit({ ...values, meetingInfo });
      const updateData = await caUpdateData(inEditCa.id, {
        ...values,
        meetingInfo,
      });
      if (updateData.success) {
        message.success(updateData.message);
      } else {
        message.error(updateData.message);
      }
    } catch (error) {
      console.error("Update CA Commission data failed:", error); // Log update errors
    }
  };

  return (
    <Modal
      title="Edit CA Details"
      visible={visible}
      onCancel={onClose}
      width={800}
      footer={null}
    >
      <TabsComponent
        onMeetingInfoChange={handleMeetingInfoChange}
        meetingInfoEditable={meetingInfoEditable}
        toggleEditable={toggleEditable}
        caForm={
          <Form form={form} layout="vertical" className="font-semibold">
            <div className="grid grid-cols-2 ">
              {" "}
              <Form.Item
                name="caFirmName"
                label="CA Firm Name"
                rules={[
                  { required: true, message: "Please enter CA Firm Name" },
                ]}
                className="px-4"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="caName"
                label="CA Name"
                rules={[{ required: true, message: "Please enter CA Name" }]}
                className="px-4"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="caEmailId"
                label="CA Email"
                rules={[{ required: true, message: "Please enter Email ID" }]}
                className="px-4"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="caContactNumber"
                label="CA Contact Number"
                rules={[
                  { required: true, message: "Please enter contact number" },
                ]}
                className="px-4"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="bdm_name"
                label="BDM Name"
                rules={[{ required: true, message: "Please select BDM Name" }]}
                className="px-4"
              >
                <Select disabled>
                  {bdmNames.map((bdm) => (
                    <Option key={bdm} value={bdm}>
                      {bdm}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="status" label="Status" className="px-4">
                <Select disabled>
                  {statusOptions.map((status) => (
                    <Option key={status} value={status}>
                      {status}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Form.Item name="remarks" label="Remarks" className="px-4">
              <Input.TextArea rows={3} />
            </Form.Item>
            <div className="mt-4 flex justify-end space-x-3 px-4">
              <Button onClick={onClose}>Cancel</Button>
              <Button type="primary" onClick={handleOk}>
                Save
              </Button>
            </div>
          </Form>
        }
        meetingInfo={meetingInfo}
      />
    </Modal>
  );
};

export default EditCaModal;
