import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Typography, InputNumber } from "antd";
import { caRegistration } from "api/ca";
import { getId } from "helper/EncrptionUtils";
import { useNavigate } from "react-router-dom";
import { getCaCommision } from "helper/EncrptionUtils";

const { Title } = Typography;

const CaForm = () => {
  const [formData, setFormData] = useState({
    caFirmName: "",
    caName: "",
    caContactNumber: "",
    caEmailId: "",
    remarks: "",
  });
  let navigate = useNavigate();
  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const { caFirmName, caName, caContactNumber, caEmailId } = formData;

    // Validate required fields
    if (!caName || !caContactNumber || !caEmailId) {
      message.error("Please fill in all required fields.");
      return;
    }

    if (caContactNumber.toString().length != 10) {
      message.error("Please enter 10-digit Mobile Number");
      return;
    }

    try {
      const response = await caRegistration(getId(), formData);
      if (response.success) {
        message.success(response.message);
        setFormData({
          caFirmName: "",
          caName: "",
          caContactNumber: "",
          caEmailId: "",
          remarks: "",
        });
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.log("error ca commission form", error);
    }
  };

  useEffect(() => {
    if (!getCaCommision()) {
      navigate("/");
    }
  }, []);

  return (
    <div className="mx-auto mt-6 max-w-4xl rounded-lg bg-gray-100 p-5 font-semibold shadow-lg">
      <Title level={3} className="text-center">
        CA Commission Form
      </Title>
      <Form layout="vertical">
        {/* CA Firm Name */}
        <Form.Item label="CA Firm Name">
          <Input
            placeholder="Enter CA Firm Name"
            value={formData.caFirmName}
            onChange={(e) => handleInputChange("caFirmName", e.target.value)}
            style={{ height: "48px", borderRadius: "8px" }}
          />
        </Form.Item>

        {/* CA Name */}
        <Form.Item label="CA Name" required>
          <Input
            placeholder="Enter CA Name"
            value={formData.caName}
            onChange={(e) => handleInputChange("caName", e.target.value)}
            style={{ height: "48px", borderRadius: "8px" }}
          />
        </Form.Item>

        {/* CA Contact Number */}
        <Form.Item label="CA Contact Number" required>
          <InputNumber
            placeholder="Enter CA Contact Number"
            value={formData.caContactNumber}
            onChange={(value) => handleInputChange("caContactNumber", value)}
            style={{
              width: "100%",
              height: "48px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center", // Vertically center the input text
            }}
            maxLength={10}
          />
        </Form.Item>

        {/* CA Email ID */}
        <Form.Item label="CA Email ID" required>
          <Input
            placeholder="Enter CA Email ID"
            value={formData.caEmailId}
            onChange={(e) => handleInputChange("caEmailId", e.target.value)}
            style={{ height: "48px", borderRadius: "8px" }}
          />
        </Form.Item>

        {/* Remarks */}
        <Form.Item label="Remarks">
          <Input.TextArea
            placeholder="Enter remarks here"
            value={formData.remarks}
            onChange={(e) => handleInputChange("remarks", e.target.value)}
            style={{ borderRadius: "8px", height: "100px" }}
          />
        </Form.Item>

        <div className="mt-5 flex justify-center">
          <Button
            type="primary"
            size="large"
            onClick={handleSubmit}
            style={{
              borderRadius: "8px",
              padding: "10px 20px",
              fontSize: "16px",
            }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CaForm;
