import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminLayout from "./layouts/admin";
import { Toaster } from "react-hot-toast";
import LoginPage from "./authentication/LoginPage";
import { CaProvider } from "context/CaContext";
import { NaifProvider } from "context/NaifContext";

const App = () => {
  return (
    <CaProvider>
      <NaifProvider>
        <Toaster />
        <Routes>
          <Route path="admin/*" element={<AdminLayout />} />
          {/* <Route path="rtl/*" element={<RtlLayout />} /> */}
          <Route path="/" element={<LoginPage />} />
        </Routes>
      </NaifProvider>
    </CaProvider>
  );
};

export default App;
