import React, { useState, useEffect } from "react";
import { Button, Select, message } from "antd";
import { FiEdit, FiSave } from "react-icons/fi"; // Import icons from react-icons
import { statusUpdateApplication } from "api/applications";
import { getId } from "helper/EncrptionUtils";

const NaifStatus = ({ data }) => {
  const [editable, setEditable] = useState(false); // For toggling between edit and view mode
  const [status, setStatus] = useState(data?.status); // Status from `data` prop
  const bdm_id = getId();
  // Status options in an array
  const statusOptions = [
    "Documents Pending",
    "Validation Inprocess",
    "Validation Completed",
    "Lost",
  ];

  // Handle status change in dropdown
  const handleStatusChange = (value) => {
    setStatus(value);
  };

  // Toggle edit/save mode
  const handleEditClick = () => {
    setEditable(!editable);
  };

  // Handle save functionality
  const handleSave = async () => {
    if (status === data?.status) {
      message.info("No changes made.");
      setEditable(false);
      return;
    }
    try {
      const responce = await statusUpdateApplication(data.id, {
        status: status,
        bdm_id: bdm_id,
      });
      if (responce.success) {
        message.success(responce.message);
        setEditable(false); // Disable edit mode after saving
      } else {
        message.error(responce.message);
      }
    } catch (error) {
      console.log("status update error", error);
    }
  };

  return (
    <div className="h-48">
      <div className="mb-4">
        <div className="mr-10 flex justify-end">
          {/* Edit/Save Button with Icon */}
          <Button
            onClick={editable ? handleSave : handleEditClick} // Save if in edit mode, otherwise edit
            type={editable ? "default" : "primary"}
            icon={editable ? <FiSave /> : <FiEdit />} // Conditionally render icons
          >
            {editable ? "Save" : "Edit"}
          </Button>
        </div>
        {/* Dropdown for selecting status */}
        <div className="mt-4">
          <label>Status Of Application : </label>
          <Select
            value={status}
            onChange={handleStatusChange}
            disabled={!editable} // Disable dropdown if not in edit mode
            style={{ width: 200 }}
          >
            {/* Mapping over the statusOptions array to display each option */}
            {statusOptions.map((statusOption, index) => (
              <Select.Option key={index} value={statusOption}>
                {statusOption}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default NaifStatus;
