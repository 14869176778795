import CryptoJS from "crypto-js";

export const encrypt = (value) => {
  if (typeof value !== "string") {
    throw new Error("Value to encrypt must be a string");
  }
  const encrypted = CryptoJS.AES.encrypt(
    value,
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return encrypted;
};

export const decrypt = (value) => {
  if (!value) return "";

  const decrypted = CryptoJS.AES.decrypt(
    value,
    process.env.REACT_APP_SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  return decrypted;
};

export const getNaif = () => {
  return decrypt(localStorage.getItem("naif"));
};

export const getCaCommision = () => {
  return decrypt(localStorage.getItem("caCommission"));
};

export const getEmail = () => {
  return localStorage.getItem("email");
};

export const getName = () => {
  return decrypt(localStorage.getItem("name"));
};
export const getId = () => {
  return decrypt(localStorage.getItem("id"));
};
