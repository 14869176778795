import React, { useState, useRef, useContext } from "react";
import { Button, message, Space, Card, Tooltip, Spin } from "antd"; // Import Spin
import {
  UploadOutlined,
  EyeOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useCallback } from "react";
import axios from "axios";
import { CaContext } from "context/CaContext";

const MouUpload = () => {
  const caContext = useContext(CaContext);
  const { inEditCa, setInEditCa } = caContext;

  const [file, setFile] = useState(null); // To store the uploaded file
  const [isEditing, setIsEditing] = useState(false); // Track if user is editing/re-uploading the file
  const [loading, setLoading] = useState(false); // Track the loading state
  const fileInputRef = useRef(null); // Ref for the hidden file input

  const handleFileChange = useCallback((e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      message.success(`${selectedFile.name} uploaded successfully.`);
      setIsEditing(false); // Disable editing after file selection
      e.target.value = ""; // Reset file input to allow re-selecting the same file
    }
  }, []);

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is not available.");
    }
  };

  const handleViewFile = () => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    } else if (inEditCa.document_url) {
      window.open(inEditCa.document_url, "_blank");
    } else {
      message.error("Document not Uploaded Yet!");
    }
  };

  const handleSaveDocument = async () => {
    if (file) {
      setLoading(true); // Start loading
      try {
        const documentData = new FormData();
        documentData.append("app_id", inEditCa.id);
        documentData.append("type", "ca");
        documentData.append("ca_document_id", inEditCa.document_id);
        documentData.append("files", file);

        const { data } = await axios.post(
          `${process.env.REACT_APP_URL}/api/ca/upload-mou`,
          documentData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (data.success) {
          message.success(`Mou Uploaded successfully!`);
          setInEditCa({
            ...inEditCa,
            document_url: data.document_urls.length > 0 ? data.document_urls[0] : null,
            document_name: file.name
          });
          setFile(null);
        } else {
          message.error(`Error Uploading Documents!`);
        }
      } catch (error) {
        console.error(error);
        message.error(`Error Uploading Documents!`);
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      message.warning("No file to save.");
    }
  };

  return (
    <div className="upload-container" style={{ padding: "20px" }}>
      <Spin spinning={loading} tip="Processing..."> {/* Add Spin component */}
        <Card
          bordered={false}
          style={{
            width: "100%",
            maxWidth: "600px",
            margin: "0 auto",
            padding: "20px",
          }}
        >
          {inEditCa.document_name || file ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  marginBottom: "10px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#1890ff",
                }}
              >
                <span>{file?.name || inEditCa.document_name}</span>
              </div>

              <Space size="large">
                <Tooltip title="View Document">
                  <EyeOutlined
                    onClick={handleViewFile}
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "#1890ff",
                    }}
                  />
                </Tooltip>

                <Tooltip title="Re-upload Document">
                  <EditOutlined
                    onClick={() => setIsEditing(true)}
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "#fa8c16",
                    }}
                  />
                </Tooltip>
              </Space>
            </div>
          ) : (
            <div
              className="text-base"
              style={{ marginBottom: "20px", textAlign: "center" }}
            >
              Please upload a document.
            </div>
          )}
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
          />

          {(!file && !inEditCa.document_name) && (
            <Button
              icon={<UploadOutlined />}
              onClick={triggerFileInput}
              type="primary"
              block
              size="large"
              style={{ marginTop: "20px" }}
            >
              Upload Document
            </Button>
          )}

          {isEditing && (
            <Button
              icon={<UploadOutlined />}
              onClick={triggerFileInput}
              type="default"
              block
              size="large"
              style={{
                marginTop: "20px",
                backgroundColor: "#f0f5ff",
                borderColor: "#91d5ff",
              }}
              className="mt-20 bg-gray-300"
            >
              Re-upload Document
            </Button>
          )}
          {file && (
            <Button
              icon={<SaveOutlined />}
              onClick={handleSaveDocument}
              type="primary"
              block
              size="large"
              style={{
                marginTop: "20px",
                backgroundColor: "#4CAF50",
                borderColor: "#45a049",
              }}
            >
              Save Document
            </Button>
          )}
        </Card>
      </Spin>
    </div>
  );
};

export default MouUpload;
