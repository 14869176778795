import client from "./client";

export const userLogin = async (obj) => {
  try {
    const data = await client.post("/user/login", obj);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const allUser = async () => {
  try {
    const data = await client.get("/user/naifUser");
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
