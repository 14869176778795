import React, { useState, useEffect, useRef } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import avatar from "assets/img/avatars/user.png";
import { IoClose } from "react-icons/io5";
import Card from "components/card";

import { toast } from "react-toastify";

import { getEmail, getName } from "../../helper/EncrptionUtils";

const Navbar = (props) => {
  let navigate = useNavigate();
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const name = getName();

  const modalRef = useRef(null);

  // Function to handle search
  const handleSearch = async (searchTerm) => {
    try {
      console.log("hello");
    } catch (error) {
      console.log("Error fetching ticket:", error);
    }
  };

  // Handle 'Enter' key press for search
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch(searchTerm); // Pass the correct search term
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("naif");
    localStorage.removeItem("caCommission");
    localStorage.removeItem("name");
    navigate("/");
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setShowModal(false);
      }
    };

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setShowModal(false);
      }
    };

    window.addEventListener("keydown", handleEscape);
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("keydown", handleEscape);
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
        <div className="ml-[6px]">
          <div className="h-6 w-[224px] pt-1">
            <a
              className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              href=" "
            >
              Pages
              <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                {" "}
                /{" "}
              </span>
            </a>
            <Link
              className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              to="#"
            >
              {brandText}
            </Link>
          </div>
          <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
            <Link
              to="#"
              className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
            >
              {brandText}
            </Link>
          </p>
        </div>

        {/* Search Bar */}
        <div className="relative mt-[3px] flex h-[61px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none  md:flex-grow-0 md:gap-1 xl:gap-2">
          {/* <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2"></div> */}
          {/* <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
              type="text"
              placeholder="Search by Ticket ID, Name, Or contact"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Update search term
              onKeyPress={handleKeyPress} // Execute search on Enter key press
              className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
            />
          </div> */}

          {/* <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
            onClick={onOpenSidenav}
          >
            <FiAlignJustify className="h-5 w-5" />
          </span> */}

          {/* Dark Mode Toggle */}
          {/* <div
            className="cursor-pointer text-gray-600"
            onClick={() => {
              if (darkmode) {
                document.body.classList.remove("dark");
                setDarkmode(false);
              } else {
                document.body.classList.add("dark");
                setDarkmode(true);
              }
            }}
          >
            {darkmode ? (
              <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
            ) : (
              <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
            )}
          </div> */}

          {/* Profile & Dropdown */}
          <Dropdown
            button={
              <img className="h-10 w-10 rounded-full" src={avatar} alt="user" />
            }
            children={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      👋 Hey , {name}
                    </p>{" "}
                  </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="flex flex-col p-4">
                  <button
                    href=""
                    onClick={() => {
                      handleLogout();
                    }}
                    className=" text-sm font-medium text-red-500 transition duration-150 ease-out hover:text-red-500 hover:ease-in"
                  >
                    Log Out
                  </button>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </nav>

      {/* Modal to show search results */}
      {showModal && (
        <div className="bg-black fixed inset-0 z-50 mt-10 flex h-fit justify-center bg-opacity-50">
          <div
            ref={modalRef}
            className="relative w-[90%] max-w-[800px] rounded-lg bg-white p-6 shadow-lg dark:bg-navy-800 dark:text-white"
          >
            {/* Modal Header */}
            <div className="mb-4 flex items-center justify-between border-b border-gray-300 pb-2 dark:border-gray-700">
              <h2 className="text-xl font-semibold text-navy-800 dark:text-white">
                Search Results
              </h2>
              <button
                className="text-gray-500 hover:text-red-500"
                onClick={() => setShowModal(false)}
              >
                <IoClose size={24} />
              </button>
            </div>

            {/* Modal Content */}
            {searchResults.length > 0 ? (
              <Card extra="p-6 col-span-2 flex flex-col space-y-4">
                {searchResults.map((result, index) => (
                  <div key={index} className="grid grid-cols-2 gap-4">
                    {/* Ticket ID */}
                    <div className="rounded-lg border border-gray-200 p-3 tracking-wide">
                      <span className="font-semibold">TICKET ID:</span>
                      <span className="block font-semibold text-gray-500 dark:text-white">
                        {result.ticketId}
                      </span>
                    </div>

                    {/* Customer Name */}
                    <div className="rounded-lg border border-gray-200 p-3 tracking-wide">
                      <span className="font-semibold">CUSTOMER NAME:</span>
                      <span className="block font-semibold text-gray-500 dark:text-white">
                        {result.customer_name}
                      </span>
                    </div>

                    {/* Customer Number */}
                    <div className="rounded-lg border border-gray-200 p-3 tracking-wide">
                      <span className="font-semibold">CUSTOMER NUMBER:</span>
                      <span className="block font-semibold text-gray-500 dark:text-white">
                        {result.customer_number}
                      </span>
                    </div>

                    {/* Source */}
                    <div className="rounded-lg border border-gray-200 p-3 tracking-wide">
                      <span className="font-semibold">SOURCE:</span>
                      <span className="block font-semibold text-gray-500 dark:text-white">
                        {result.source}
                      </span>
                    </div>

                    {/* Escalation Level */}
                    <div className="rounded-lg border border-gray-200 p-3 tracking-wide">
                      <span className="font-semibold">ESCALATION LEVEL:</span>
                      <span className="block font-semibold text-gray-500 dark:text-white">
                        {result.escalationLevel}
                      </span>
                    </div>

                    {/* Status */}
                    <div className=" rounded-lg border border-gray-200 p-3 tracking-wide">
                      <span className="font-semibold">STATUS:</span>
                      <span className="block font-semibold text-gray-500 dark:text-white">
                        {result.status}
                      </span>
                    </div>

                    {/* Issue Type */}
                    <div className=" rounded-lg border border-gray-200 p-3 tracking-wide">
                      <span className="font-semibold">ISSUE TYPE:</span>
                      <span className="block font-semibold text-gray-500 dark:text-white">
                        {result.issueType}
                      </span>
                    </div>

                    {/* Message */}
                    <div className=" rounded-lg border border-gray-200 p-3 tracking-wide">
                      <span className="font-semibold">MESSAGE:</span>
                      <span className="block font-semibold text-gray-500 dark:text-white">
                        {result.message}
                      </span>
                    </div>

                    {/* Remarks */}
                    <div className=" rounded-lg border border-gray-200 p-3 tracking-wide">
                      <span className="font-semibold">REMARKS:</span>
                      <span className="block font-semibold text-gray-500 dark:text-white">
                        {result.remarks}
                      </span>
                    </div>

                    {/* RM Name */}
                    <div className=" rounded-lg border border-gray-200 p-3 tracking-wide">
                      <span className="font-semibold">RM NAME:</span>
                      <span className="block font-semibold text-gray-500 dark:text-white">
                        {result.rm_name}
                      </span>
                    </div>
                  </div>
                ))}
              </Card>
            ) : (
              <p className="text-center text-gray-500 dark:text-white">
                No Results Found.
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
